import React, { useContext, useState } from 'react';
import { Box, Text, Input, VStack, HStack, Button, IconButton, Icon, Flex, useToast } from '@chakra-ui/react';
import { FaPlus, FaMinus, FaCoins } from 'react-icons/fa';
import { ArrowBackIcon, CloseIcon } from '@chakra-ui/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { counterCollab, updateCollabCounter } from '../../../services/firebaseService';
import { Collab, Campaign } from '../../../types';
import { sendCounterEmail } from '../../../services/flaskService';
import { InfluencerDataContext } from 'contexts/InfluencerDataContext';

const CounterCollabPage: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const { collab, campaign, reason }: { collab: Collab, campaign: Campaign, reason: string } = location.state || {};

  const initialCash = collab.status === 'pendingInfluencerApproval' 
    ? collab.cash || 0 
    : collab.proposedInfluencerCash || 0;

  const initialCredit = collab.status === 'pendingInfluencerApproval'
    ? collab.credit || 0
    : collab.proposedInfluencerCredit || 0;

  const initialTikTokCount = collab.status === 'pendingInfluencerApproval'
    ? collab.deliverableCounts.tiktoks || 0
    : collab.proposedInfluencerDeliverableCounts?.tiktoks || 0;

  const initialIgReelCount = collab.status === 'pendingInfluencerApproval'
    ? collab.deliverableCounts.instagramReels || 0
    : collab.proposedInfluencerDeliverableCounts?.instagramReels || 0;

  const initialIgPostCount = collab.status === 'pendingInfluencerApproval'
    ? collab.deliverableCounts.instagramPosts || 0
    : collab.proposedInfluencerDeliverableCounts?.instagramPosts || 0;

  const initialIgStoryCount = collab.status === 'pendingInfluencerApproval'
    ? collab.deliverableCounts.instagramStories || 0
    : collab.proposedInfluencerDeliverableCounts?.instagramStories || 0;

  const [tikTokCount, setTikTokCount] = useState(Number(initialTikTokCount));
  const [igReelCount, setIgReelCount] = useState(Number(initialIgReelCount));
  const [igPostCount, setIgPostCount] = useState(Number(initialIgPostCount));
  const [igStoryCount, setIgStoryCount] = useState(Number(initialIgStoryCount));
  const [cashAmount, setCashAmount] = useState(initialCash);
  const [creditAmount, setCreditAmount] = useState(initialCredit);
  const { influencerData } = useContext(InfluencerDataContext);

  const handleIncrement = (setter: React.Dispatch<React.SetStateAction<number>>, value: number) => {
    setter(value + 1);
  };

  const handleDecrement = (setter: React.Dispatch<React.SetStateAction<number>>, value: number) => {
    if (value > 0) {
      setter(value - 1);
    }
  };

  const handleSubmit = async () => {
    try {
      if(collab.status === 'pendingInfluencerApproval'){
      await counterCollab(collab.id, {
          tiktoks: tikTokCount,
          instagramReels: igReelCount,
          instagramPosts: igPostCount,
          instagramStories: igStoryCount,
        }, Number(cashAmount), Number(creditAmount), reason, collab.counters || []);

        sendCounterEmail(campaign.clientEmail, influencerData.instagramHandle, campaign.clientID);

      } else {
        await updateCollabCounter(collab.id, {
          tiktoks: tikTokCount,
          instagramReels: igReelCount,
          instagramPosts: igPostCount,
          instagramStories: igStoryCount,
        }, Number(cashAmount), Number(creditAmount), collab.counters);
      }
      toast({
        title: "Counter offer submitted",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate('/collabs');
    } catch (error) {
      console.error('Error submitting counter offer:', error);
      toast({
        title: "Error submitting counter offer",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  
  const hasChanges = (): boolean => {
    return (
      tikTokCount !== initialTikTokCount ||
      igReelCount !== initialIgReelCount ||
      igPostCount !== initialIgPostCount ||
      igStoryCount !== initialIgStoryCount ||
      cashAmount !== initialCash ||
      creditAmount !== initialCredit
    );
  };

  const isSubmitEnabled =
    (tikTokCount > 0 || igReelCount > 0 || igPostCount > 0 || igStoryCount > 0) &&
    (campaign?.campaignType === 'shipToHome' || cashAmount > 0 || creditAmount > 0) &&
    hasChanges();

  const handleCashChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (!isNaN(value)) {
      setCashAmount(value);
    }
  };

  const handleCreditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (!isNaN(value)) {
      setCreditAmount(value);
    }
  };

  return (
    <Flex minH="100vh" direction="column" align="center" bg="white">
      {/* Fixed Header */}
      <Box position="relative" w="100%"  p={4} display="flex" justifyContent="space-between" alignItems="center" zIndex={1}>
        <IconButton
          icon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
          aria-label="Go back"
          bg="white"
          color="black"
          _hover={{ bg: "gray.100" }}
        />
        <Text fontSize="2xl" fontWeight="bold" textAlign="center" flex="1">Counter Offer</Text>
        <IconButton
          icon={<CloseIcon />}
          onClick={() => navigate(-1)}
          aria-label="Close"
          bg="white"
          color="black"
          _hover={{ bg: "gray.100" }}
        />
      </Box>

      {/* Main Content */}
      <Flex direction="column" justify="space-between" bg="white" p={4} maxW="400px" w="100%" flex="1">
        <VStack spacing={4} align="stretch">
          {/* Deliverables Section */}
          <Text fontSize="xl" fontWeight="bold">Deliverables</Text>
          <Box borderWidth="1px" borderRadius="md" p={4}>
            <VStack spacing={3} align="stretch">
              {[
                { label: 'TikTok', count: tikTokCount, setCount: setTikTokCount },
                { label: 'IG Reel', count: igReelCount, setCount: setIgReelCount },
                { label: 'IG Post', count: igPostCount, setCount: setIgPostCount },
                { label: 'IG Story', count: igStoryCount, setCount: setIgStoryCount },
              ].map(({ label, count, setCount }) => (
                <HStack key={label} justify="space-between">
                  <Text fontSize="md" fontWeight="medium">{label}</Text>
                  <HStack>
                    <IconButton
                      icon={<FaMinus style={{ color: 'red' }} />}
                      aria-label={`Decrease ${label}`}
                      onClick={() => handleDecrement(setCount, count)}
                      size="sm"
                      variant="ghost"
                      isDisabled={count === 0}
                    />
                    <Text>{count}</Text>
                    <IconButton
                      icon={<FaPlus style={{ color: 'green' }} />}
                      aria-label={`Increase ${label}`}
                      onClick={() => handleIncrement(setCount, count)}
                      variant="ghost"
                      size="sm"
                    />
                  </HStack>
                </HStack>
              ))}
            </VStack>
          </Box>

          {/* Compensation Section */}
          
          <Text fontSize="xl" fontWeight="bold">Compensation</Text>
          <Text fontWeight="bold" fontSize="18px">Credit</Text>
          {campaign?.campaignType === 'shipToHome' ? (
            <Input
              value={'Credit not accepted'}
              readOnly
              bg="gray.100"
          />
          ) : (
            <Input
              value={creditAmount}
              onChange={handleCreditChange}
            />
          )}

          <Text fontWeight="bold" fontSize="18px" display="inline">
            Cash <span style={{ fontWeight: "lighter", fontSize: '16px' }}>(paid after content is verified) </span>
          </Text>
          <Input
            value={cashAmount}
            onChange={handleCashChange}
          />
          
        </VStack>

        {/* Submit Button at the Bottom */}
        <Button
          mt={4}
          colorScheme={isSubmitEnabled ? 'blackAlpha' : 'gray'}
          onClick={handleSubmit}
          bg={isSubmitEnabled ? 'black' : 'gray.300'}
          color={isSubmitEnabled ? 'white' : 'gray.500'}
          _hover={{ bg: isSubmitEnabled ? 'blackAlpha.800' : 'gray.300' }}
          width="100%"
          fontWeight="bold"
          isDisabled={!isSubmitEnabled}
          alignSelf="flex-end"
        >
          Submit
        </Button>
      </Flex>
    </Flex>
  );
};

export default CounterCollabPage;
