import { Campaign, Collab } from '../../types';

/**
 * Checks if a campaign has ended based on its end date and collab status
 * @param campaign The campaign to check
 * @param collab The collab to check
 * @returns True if the campaign has ended, false otherwise
 */
export const isCampaignEnded = (campaign: Campaign, collab: Collab): boolean => {
    if (!campaign?.endDate || campaign.endDate.toDate() >= new Date()) {
        return false;
    }

    if (collab.status === "pendingInfluencerApproval") {
        return true;
    }

    if (collab.status === "pendingRedemption") {
        if (campaign.campaignType === "shipToHome") {
            return false;
        }
        return !collab.scheduledCollabDate;
    }

    return false;
};