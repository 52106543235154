import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  VStack,
  Text,
  Box,
  Button,
  IconButton,
  ModalHeader,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { Campaign, Collab } from 'types';
import CancelExplanationModal from './CancelExplanationModal';
import ReschedulePopup from './ReschedulePopup';

interface CancelOptionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onBack: () => void;
  onConfirm: (explanation: string) => void;
  // collab: Collab;
  // campaign: Campaign;
  clientName: string;
}

const CancelOptionsModal: React.FC<CancelOptionsModalProps> = ({ isOpen, onClose, onConfirm, onBack, clientName }) => {
  const [selectedReason, setSelectedReason] = useState<string | null>(null);
  const [isExplainCancelModalOpen, setIsExplainCancelModalOpen] = useState(false);
  const [isReschedulePopupOpen, setIsReschedulePopupOpen] = useState(false);
  const navigate = useNavigate();

  const cancelOptions = {
    "A":'A schedule conflict came up',
    "B":'No longer want to collaborate with this brand due to brand fit',
    "C":'My compensation rates have changed since I accepted this collab',
    "D":'Other'
  };

  const handleOptionClick = (key: string) => {
    const reason = key;
    setSelectedReason(reason === selectedReason ? null : reason);
  };

  const handleSubmit = () => {
    if (selectedReason === "B" || selectedReason === "C") {
      handleConfirmCancel();
    } else if (selectedReason === "D") {
      setIsExplainCancelModalOpen(true); 
      onClose();
    } else {
      setIsReschedulePopupOpen(true);
      onClose();
    }
  };

  const handleConfirmCancel = () => {
    onConfirm(cancelOptions[selectedReason as keyof typeof cancelOptions]);
    //setIsConfirmRejectModalOpen(false);
    onClose();
  }

  const handleExplainSubmit = (explanation: string) => {
    // Handle the submission of the explanation from ExplainReasonModal
    onConfirm(explanation);
    setIsExplainCancelModalOpen(false);
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          maxWidth="360px"
          padding="4"
          borderRadius="12px"
          boxShadow="lg"
          bg="white"
        >
          <ModalHeader>
            <IconButton
              icon={<CloseIcon />}
              aria-label="Close"
              onClick={onClose}
              variant="ghost"
              position="absolute"
              left={4}
              top={4}
            />
          </ModalHeader>
          <ModalBody>
            <Text fontSize="2xl" fontWeight="bold" textAlign="left" color="black" mt={2} mb={2}>
              Want to Cancel?
            </Text>
            <Text fontSize="xl" fontWeight="normal" textAlign="left" color="gray.600" mb={4}>
              Let us know why!
            </Text>
            <VStack align="start" spacing={4}>
              {Object.entries(cancelOptions).map(([key, option]) => (
                <Box
                  key={key}
                  borderWidth="1px"
                  borderRadius="md"
                  p={3}
                  w="100%"
                  cursor="pointer"
                  fontSize="small"
                  fontWeight="bold"
                  color="gray.600"
                  onClick={() => handleOptionClick(key)}
                  bg={selectedReason === key ? 'gray.100' : 'white'}
                >
                  {`${key}) ${option}`}
                </Box>
              ))}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button 
              colorScheme="blackAlpha" 
              onClick={handleSubmit} 
              isDisabled={!selectedReason}
              bg="black"                    
              color="white"
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <CancelExplanationModal
        isOpen={isExplainCancelModalOpen}
        onClose={() => setIsExplainCancelModalOpen(false)}
        onBack={onBack} // calls onBack to re-open CancelOptionsModal (passed from ScheduleCollabPage)
        onConfirm={handleExplainSubmit}
        clientName={clientName}
      />

      <ReschedulePopup
        isOpen={isReschedulePopupOpen}
        onClose={() => setIsReschedulePopupOpen(false)}
        clientName={clientName}
      />
    </>
  );
};

export default CancelOptionsModal;