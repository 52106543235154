import React, { useState } from 'react';
import { Box, Text, Image, Flex, Button, useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import CompletedCollabModal from './CompletedCollabModal';
import CanceledCollabModal from './CanceledCollabModal';
import ExpiredCollabModal from './ExpiredCollabModal';
import { Collab, Campaign } from '../../../types';
import defaultImage from '../../../assets/foodfluence_default_image.jpg';
import CampaignEndedModal from './CampaignEndedModal';

interface PastCollabComponentProps {
  collab: Collab;
  campaign: Campaign;
  influencerName: string;
}

const PastCollabComponent: React.FC<PastCollabComponentProps> = ({ collab, campaign, influencerName }) => {
  const navigate = useNavigate();
  const completedModalDisclosure = useDisclosure();
  const canceledModalDisclosure = useDisclosure();
  const expiredModalDisclosure = useDisclosure();
  const campaignEndedModalDisclosure = useDisclosure();
  const [randomImage, setRandomImage] = useState<string | null>(null);

  function importAll(r) {
    return r.keys().map(r);
  }
  
  const images = importAll((require as any).context('../../../assets/default_collab_images', false, /\.(png|jpe?g|svg)$/));
  
  const getRandomImage = () => {
    if (!randomImage) {
      const randomIndex = Math.floor(Math.random() * images.length);
      setRandomImage(images[randomIndex]);
      return images[randomIndex];
    }
    return randomImage;
  };

  const handleCollabClick = () => {
    switch (collab.status) {
      case 'collabCompleted':
        completedModalDisclosure.onOpen();
        break;
      case 'collabRejected':
        canceledModalDisclosure.onOpen();
        break;
      case 'collabCanceled':
        canceledModalDisclosure.onOpen();
        break;
      case 'collabExpired':
        expiredModalDisclosure.onOpen();
        break;
      default:
        campaignEndedModalDisclosure.onOpen();
    }
  }
  
  const actionNames = {
    collabCompleted: "Completed",
    collabRejected: "Rejected",
    collabCanceled: "Canceled",
    collabExpired: "Expired",
  }

  return (
    <>
      <Box 
        borderWidth={1} 
        borderRadius="lg" 
        overflow="hidden" 
        boxShadow="md"  
        bg="white" 
        width="150px"
        height="200px" 
        display="flex"
        flexDirection="column"
        m={2} 
        cursor="pointer" 
        _hover={{ boxShadow: "lg" }}
        position="relative"
        onClick={handleCollabClick}
      >
        <Image 
          src={campaign?.collabImage ?? getRandomImage()} 
          height="50%" 
          width="100%" 
          objectFit="cover"
          onError={(e) => { 
            if (!randomImage) {
              e.currentTarget.src = getRandomImage();
            }
          }}
        />
        <Box p={2} flex={1} display="flex" flexDirection="column">
          <Flex direction="row" justifyContent="space-between">
          <Text color="black" mb={3} fontSize="sm" fontWeight="bold">{campaign?.clientName}</Text>
          </Flex>
          <Text color={collab.status === 'collabCompleted' ? "green.500" : "gray.500"}>
            {collab.status in actionNames ? actionNames[collab.status] : "Campaign Ended"}
          </Text>
        </Box>
      </Box>
      
      <CompletedCollabModal
        isOpen={completedModalDisclosure.isOpen}
        onClose={completedModalDisclosure.onClose}
        collabId={collab.id}
        userConfirmRedemption={collab.userConfirmRedemption}
      />
      <CanceledCollabModal
        isOpen={canceledModalDisclosure.isOpen}
        onClose={canceledModalDisclosure.onClose}
        clientName={campaign?.clientName ?? ""}
      />
      <ExpiredCollabModal
        isOpen={expiredModalDisclosure.isOpen}
        onClose={expiredModalDisclosure.onClose}
        clientName={campaign?.clientName ?? ""}
      />
      <CampaignEndedModal
        isOpen={campaignEndedModalDisclosure.isOpen}
        onClose={campaignEndedModalDisclosure.onClose}
        clientName={campaign?.clientName ?? ""}
      />
    </>
  );
}

export default PastCollabComponent;