import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Text, Textarea, IconButton } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

interface ReschedulePopupProps {
  isOpen: boolean;
  onClose: () => void;
  clientName: string;
}

const ReschedulePopup: React.FC<ReschedulePopupProps> = ({ isOpen, onClose, clientName }) => {

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        maxWidth="360px"          
        padding="4"              
        borderRadius="12px"       
        boxShadow="lg"            
        bg="white"                
      >
        <ModalHeader>
          <IconButton
            icon={<CloseIcon/>}
            aria-label="Back"
            onClick={onClose} 
            variant="ghost"
            position="absolute"
            left={4}
            top={4}
          />
        </ModalHeader>
        <ModalBody>
          <Text fontSize="2xl" fontWeight="bold" textAlign="left" color="black" mt={2} mb={2}>
            Schedule Conflict?
          </Text>
          {/* <Text fontSize="xl" fontWeight="normal" textAlign="left" color="gray.600" mb={4}>
            Reschedule your collab with {clientName}!
          </Text>   */}
          <Text fontSize="lg" fontWeight="normal" textAlign="left" color="gray.600" mb={4}>
            Reschedule your collab with {clientName} on the calendar!
          </Text>   
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ReschedulePopup;